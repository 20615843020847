import React from 'react';
import classnames from 'classnames';
import { convert } from 'html-to-text';

const DEFAULT_TEXT = '';

const PreviewText = ({ text, className = '' }) => {
  let formattedText = text ?
    convert(text) : DEFAULT_TEXT;

  return (
    <div className={classnames(
      '',
      className
    )}>
      {formattedText}
    </div>
  )
}

export default PreviewText;
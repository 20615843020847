import {
  string
} from 'yup';
import MESSAGES from '../constans/errorMessages';

const getRules = (locale = 'EN') => ({
  required: string().required(MESSAGES[locale].required),
  email: string()
    .email(MESSAGES[locale].email)
    .required(MESSAGES[locale].required),
});

export default getRules;

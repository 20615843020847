import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const WpImage = ({
  src = null,
  sourceUrl = "",
  alt = "",
  altText = "",
  localFile = null,
  ...props
}) => {
  return !localFile ? (
    <img
      src={src || sourceUrl}
      alt={alt || altText}
      {...props}
      // loading="lazy"
      width="100%"
      height="100%"
    />
  ) : (
    <GatsbyImage
      image={localFile.childImageSharp.gatsbyImageData}
      alt={alt}
      {...props}
      placeholder="blurred"
      layout="fixed"
    // loading="lazy"
    />
  );
};

export default WpImage;

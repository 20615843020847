import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

const DateCategory = ({ date, categories, className }) => {
  return (
    <p className={classnames(
      'flex space-x-8 text-xs',
      className
    )}>
      <span className="relative after:content-[''] after:absolute after:right-[-16px] after:top-[6px] after:w-[2px] after:h-[2px] after:bg-text">{moment(date).format('MMMM D, YYYY')}</span>
      <span>{
        categories
          .filter(({ slug }) => slug !== 'uncategorized')
          .map(({ name }) => name)
          .join(', ')
      }</span>
    </p>
  )
}

export default DateCategory;
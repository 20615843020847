import React, { useMemo, useState, useCallback } from 'react';
import classnames from 'classnames';
import { useField } from 'formik';

import * as styles from './styles.module.scss';

const Input = ({
  className,
  inputClass,
  requiredField = false,
  isDisabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const statusCssClasses = useMemo(
    () => ({
      [styles.error]: meta.touched && meta.error,
      [styles.success]: meta.touched && !meta.error,
      [styles.focused]: isFocused,
      [styles.disabled]: isDisabled,
    }),
    [meta, isFocused, isDisabled],
  );

  const placeholder = useMemo(() => {
    if (!props.placeholder) return '';

    return `${props.placeholder}${requiredField ? ' *' : ''}`;
  }, [props.placeholder, requiredField]);

  const focusHandler = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const blurHandler = useCallback(
    (e) => {
      setIsFocused(false);
      field.onBlur(e);
    },
    [setIsFocused, field],
  );

  return (
    <div className={classnames(
      className, 
      statusCssClasses, 
      styles.wrap,
      'relative flex flex-col'
    )}>
      {props.label && (
        <label
          htmlFor={`input-${props.name}`}
          className="text-xs uppercase mb-1.5"
        >{props.label}{requiredField && ' *'}</label>
      )}
      <input
        id={`input-${props.name}`}
        className={classnames(
          inputClass,
          'w-full border border-white bg-transparent outline-none text-sm',
        )}
        {...field}
        {...props}
        placeholder={placeholder}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />

      {/* {meta.touched && meta.error && (
        <p className="text-red text-xs absolute right-0 top-0">
          {meta.error}
        </p>
      )} */}
    </div>
  );
};

export default Input;
import React from 'react';

import WpImage from 'components/WPImage';
import PlaceholderImage from 'images/temp/image-placeholder.webp';

const EMPTY_IMAGE = {
  src: PlaceholderImage,
  altText: 'No Image',
  srcSet: '',
};

const PreviewImage = ({ img, className }) => {
  const image = img || EMPTY_IMAGE;

  return (
    <div className={className}>
      <WpImage {...image} />
      {/* <img 
        src={image.sourceUrl}
        srcSet={image.srcSet}
        alt={image.altText}
      /> */}
    </div>
  )
}

export default PreviewImage;
import {
  object
} from 'yup';
import getRules from 'helpers/validRules';

const rules = (locale = 'en') => {
  const RULES = getRules(locale);

  return object({
    email: RULES.email,
    // first_name: RULES.required,
    // last_name: RULES.required,
  });
};

export default rules;

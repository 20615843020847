import React, { useState } from 'react';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { ArrowUpRight } from 'react-feather';
import { Transition } from 'react-transition-group';

import * as styles from './styles.module.scss'
import EmailIcon from 'assets/icons/email-circle.inline.svg';
import Input from 'components/ui/Input';
import validationSchema from './validationSchema';
import URLS from 'constans/urls';

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms cubic-bezier(0.34, 1.56, 0.64, 1), transform ${duration}ms cubic-bezier(0.34, 1.56, 0.64, 1)`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1, transform: `translate(0,0) scale(1)` },
  entered: { opacity: 1, transform: `translate(0,0) scale(1)` },
  exiting: { opacity: 0, transform: `translate(-15px, 15px) scale(0.5)` },
  exited: { opacity: 0, transform: `translate(-15px, 15px) scale(0.5)` },
};

const Subscribe = ({ className, red = false }) => {
  const [subscribed, setSubscribed] = useState(false);

  const title = red ?
    'Subscribe for our blog updates!' :
    'Receive our updates on email!';
  const btnText = `Subscribe${!red ? ' to newsletter' : ''}`;

  const handleSubmit = (values) => {
    axios.post(URLS.subscribe, {
      input_4: values.email,
      input_5: values.first_name,
      input_6: values.last_name,
    })
      .then(res => {
        setSubscribed(true);
      })
      .catch(e => {
        // console.log('error', e, e.response);
      });
  }

  return (
    <div className={classnames(
      className,
      styles.sub,
      'flex flex-col p-6 sm:p-16 rounded',
      red && styles.red,
      red && 'sm:p-8 border border-red'
    )}>
      <div className="flex flex-row items-center font-medium">
        {!red && (
          <div className="min-w-[40px] mr-6">
            <EmailIcon />
          </div>
        )}
        <p className="text-2xl sm:text-4xl">{title}</p>
      </div>
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
        }}
        validationSchema={validationSchema()}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleSubmit(values);
          }, 400);
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form
              noValidate
              className={classnames(
                'flex flex-col sm:max-w-lg lg:max-w-2xl',
                red && 'lg:max-w-lg'
              )}
            >
              <Input
                name="email"
                type="email"
                placeholder="Your Email"
                requiredField={true}
                className="mt-4 sm:mt-6"
                inputClass="h-14 sm:h-16 px-3.5 w-full border !border-grey-3 text-sm sm:text-lg"
              />
              <div className="flex flex-row">
                <Input
                  name="first_name"
                  type="text"
                  placeholder="First Name..."
                  className={classnames(
                    'mt-4 sm:mt-6 flex-1 mr-[-1px]',
                    red && 'sm:mr-4'
                  )}
                  inputClass="h-14 sm:h-16 px-3.5 w-full border !border-grey-3 text-sm sm:text-lg"
                />
                <Input
                  name="last_name"
                  type="text"
                  placeholder="Last Name..."
                  className="mt-4 sm:mt-6 flex-1"
                  inputClass="h-14 sm:h-16 px-3.5 w-full border !border-grey-3 text-sm sm:text-lg"
                />
              </div>
              <button
                type="submit"
                className={classnames(
                  'bg-red text-white text-sm sm:text-lg font-bold font-helv w-full sm:max-w-xs h-14 sm:h-16 px-3.5 flex justify-center items-center mt-4 sm:mt-6 cursor-pointer hover:opacity-60 relative',
                  red && 'sm:max-w-lg lg:max-w-lg',
                  subscribed && 'pointer-events-none opacity-60'
                )}
              >
                <span>{btnText}</span>
                <div className="absolute right-[16px] top-[19px]">
                  <Transition
                    in={(values.email && !errors.email) || subscribed}
                    timeout={duration}
                  >
                    {state => (
                      <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                      }}>
                        <ArrowUpRight />
                      </div>
                    )}
                  </Transition>
                </div>
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  )
}


export default Subscribe;